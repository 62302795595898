/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
	 * Shared Styles
	 */
  /**
	 * Global Row Styles
	 */
  /**
	 * Header Styles
	 */
  /**
	 * Body Styles
	 */
  /**
	 * Footer Styles
	 */ }
  .ngx-datatable.material.striped .datatable-row-odd {
    background: #eee; }
  .ngx-datatable.material.single-selection .datatable-body-row.active,
  .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #304ffe;
    color: #fff; }
  .ngx-datatable.material.single-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: #193ae4;
    color: #fff; }
  .ngx-datatable.material.single-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
    background-color: #2041ef;
    color: #fff; }
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: #eee;
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: linear; }
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
    background-color: #ddd; }
  .ngx-datatable.material.cell-selection .datatable-body-cell:hover,
  .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
    background-color: #eee;
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: linear; }
  .ngx-datatable.material.cell-selection .datatable-body-cell:focus,
  .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
    background-color: #ddd; }
  .ngx-datatable.material.cell-selection .datatable-body-cell.active,
  .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
    background-color: #304ffe;
    color: #fff; }
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover,
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
    background-color: #193ae4;
    color: #fff; }
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus,
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
    background-color: #2041ef;
    color: #fff; }
  .ngx-datatable.material .empty-row {
    height: 50px;
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0; }
  .ngx-datatable.material .loading-row {
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0; }
  .ngx-datatable.material .datatable-header .datatable-row-left,
  .ngx-datatable.material .datatable-body .datatable-row-left {
    background-color: #fff;
    background-position: 100% 0;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==); }
  .ngx-datatable.material .datatable-header .datatable-row-right,
  .ngx-datatable.material .datatable-body .datatable-row-right {
    background-position: 0 0;
    background-color: #fff;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==); }
  .ngx-datatable.material .datatable-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .ngx-datatable.material .datatable-header .datatable-header-cell {
      text-align: left;
      padding: 0.9rem 1.2rem;
      font-weight: 400;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.54);
      vertical-align: bottom;
      font-size: 12px;
      font-weight: 500; }
      .ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
        position: relative; }
      .ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
        transition: transform 400ms ease, opacity 400ms ease;
        opacity: 0.5;
        transform: scale(1); }
      .ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        height: 60px;
        width: 60px;
        background: #eee;
        border-radius: 100%;
        opacity: 1;
        filter: none;
        transform: scale(0);
        z-index: 9999;
        pointer-events: none; }
      .ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
        border-right: none; }
    .ngx-datatable.material .datatable-header .resize-handle {
      border-right: solid 1px #eee; }
  .ngx-datatable.material .datatable-body .datatable-row-detail {
    background: #f5f5f5;
    padding: 10px; }
  .ngx-datatable.material .datatable-body .datatable-group-header {
    background: #f5f5f5;
    border-bottom: solid 1px #d9d8d9;
    border-top: solid 1px #d9d8d9; }
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: left;
    padding: 0.9rem 1.2rem;
    vertical-align: top;
    border-top: 0;
    color: rgba(0, 0, 0, 0.87);
    transition: width 0.3s ease;
    font-size: 14px;
    font-weight: 400; }
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
    text-align: left;
    padding: 0.9rem 1.2rem;
    vertical-align: top;
    border-top: 0;
    color: rgba(0, 0, 0, 0.87);
    transition: width 0.3s ease;
    font-size: 14px;
    font-weight: 400; }
  .ngx-datatable.material .datatable-body .progress-linear {
    display: block;
    position: relative;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    position: absolute; }
    .ngx-datatable.material .datatable-body .progress-linear .container {
      display: block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 5px;
      -webkit-transform: translate(0, 0) scale(1, 1);
      transform: translate(0, 0) scale(1, 1);
      background-color: #aad1f9; }
      .ngx-datatable.material .datatable-body .progress-linear .container .bar {
        transition: all 0.2s linear;
        -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
        animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
        transition: -webkit-transform 0.2s linear;
        transition: transform 0.2s linear;
        background-color: #106cc8;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 5px; }
  .ngx-datatable.material .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54); }
    .ngx-datatable.material .datatable-footer .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem; }
    .ngx-datatable.material .datatable-footer .datatable-pager {
      margin: 0 10px; }
      .ngx-datatable.material .datatable-footer .datatable-pager li {
        vertical-align: middle; }
        .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important; }
        .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
          background-color: rgba(158, 158, 158, 0.2);
          font-weight: bold; }
      .ngx-datatable.material .datatable-footer .datatable-pager a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0 6px;
        border-radius: 3px;
        margin: 6px 3px;
        text-align: center;
        vertical-align: top;
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
        vertical-align: bottom; }
        .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
          color: rgba(0, 0, 0, 0.75);
          background-color: rgba(158, 158, 158, 0.2); }
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
        font-size: 20px;
        line-height: 20px;
        padding: 0 3px; }
  .ngx-datatable.material .datatable-summary-row .datatable-body-row {
    background-color: #ddd; }
    .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
      background-color: #ddd; }
    .ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
      font-weight: bold; }

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0; }
  .datatable-checkbox input[type='checkbox'] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none; }
    .datatable-checkbox input[type='checkbox']:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: '';
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid #f2f2f2; }
    .datatable-checkbox input[type='checkbox']:checked:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      height: 0.5rem;
      border-color: #009688;
      border-top-style: none;
      border-right-style: none; }
    .datatable-checkbox input[type='checkbox']:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #fff;
      cursor: pointer; }

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1); } }
