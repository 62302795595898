/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* NGX Datatable CSS */
@import "~assets/styles/ngx-datatable/_index.css";
@import "~assets/styles/ngx-datatable/_dark.css";
@import "~assets/styles/ngx-datatable/_material.css";
@import "~assets/styles/ngx-datatable/_bootstrap.css";
@import "~assets/styles/ngx-datatable/_icons.css";

/* Ionic */
.ion-popover-filter {
  --color: white;
  --width: 350px;
}

.ion-button {
  cursor: pointer;
}

.no-inner-padding {
  --inner-padding-end: 0;
}

.no-padding-inline-start {
  padding-inline-start: 0;
}

ion-modal {
  --height: 95%;
  --width: 90%;
}

ion-modal.xxs {
  --height: 30%;
  --width: 30%;
}

ion-modal.xs {
  --height: 50%;
  --width: 30%;
}

ion-modal.sm {
  --height: 60%;
  --width: 40%;
}

ion-modal.md {
  --height: 85%;
  --width: 60%;
}

ion-radio-group.horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ion-radio-group.horizontal ion-radio {
  margin-right: 20px;
}

ion-radio::part(container) {
  width: 30px;
  height: 30px;

  border-radius: 8px;
  border: 2px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: #6815ec;
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);
}

@media only screen and (max-width: 600px) {
  ion-modal,
  ion-modal.medium {
    --height: 100%;
    --width: 100%;
  }
}

/* Angular Material */
.mat-table-responsive {
  display: flex;
  flex-direction: column;
}

.mat-table-responsive .mat-table {
  overflow: auto;
}

.mat-table-responsive .mobile-label {
  display: none;
}

.mat-table-responsive .mat-cell,
.mat-table-responsive .mat-header-cell,
.mat-table-responsive .mat-footer-cell {
  padding: 8px 16px;
}

.mat-table-responsive button {
  width: inherit;
  height: inherit;
  padding-right: 8px;
}

@media (max-width: 768px) {
  .mat-table-responsive .mobile-label {
    display: inline-block;
    font-weight: bold;
    margin-right: 8px;
  }

  .mat-table-responsive .mat-header-row {
    display: none;
  }

  .mat-table-responsive .mat-row {
    flex-direction: column;
    align-items: start;
    padding: 8px 24px;
  }

  .mat-table-responsive .mat-cell,
  .mat-table-responsive .mat-header-cell,
  .mat-table-responsive .mat-footer-cell {
    flex-wrap: wrap;
    padding: 8px 0px;
  }
}

/* Other */

table {
  width: 100%;
}

.overflow-visible {
  overflow: visible !important;
}

.not-valid {
  color: red;
}

.trash-icon {
  font-size: 20px;
}

.action-buttons {
  margin-left: 15px;
}
